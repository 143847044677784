import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../user';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  public user: User;
  public proofLink: string;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    console.log('user: ', this.route.snapshot.paramMap.get('twitterName'));
    // this.apiService.getProofs().subscribe(proofs => { this.proofs = proofs; });
    this.apiService.getUser(this.route.snapshot.paramMap.get('twitterName'))
      .subscribe(user => { this.user = user.user; this.proofLink = user.prooflink; });
  }

}
