<!-- <div class="h3 text-center">FAQ</div> -->
<div id="question" class="h4">What is BitcoinSocialSync?</div>
<div id="answer">
  BitcoinSocialSync is an off-chain centralized proof aggregator for the Bitcoin (BTC) UTXO set.<br/>
  It's the first service allowing Bitcoin users to quickly sync a new full node, 
  by choosing WHO to trust: either the aggregate of proofs from the community, or some specific(s) member(s) in it.<br />
  This service is trying to improve on the fastsync used in 
  <a href="https://github.com/btcpayserver/btcpayserver-docker/blob/master/contrib/FastSync/" target="_blank">btcpayserver</a>
  or service like <a href="https://getbitcoinblockchain.com/" target="_blank">getbitcoinblockchain.com</a> that are rellying on very few peoples to not lie<br />
  It is very similar, in spirit, to the
  <a href="https://lists.linuxfoundation.org/pipermail/bitcoin-dev/2019-April/016825.html" target="_blank">assumeutxo proposal</a>, but build totally off the client by using RPC calls.<br />
  This allow any community member, by running a simple bash script, to sign proofs and verify those signatures in a simple way. 
</div>

<div id="question" class="h4">Why should I trust your service?</div>
<div id="answer">
  That's the beauty of it, you do not have to trust the service provider to give you valid proofs, you can verify independently that each proof is correctly signed by yourself by checking the PGP Fingerprint displayed in the signer Twitter feed.<br />
  The only way for this service to provide you with a fake UTXO-Set would be that all the signers are colluding together to lie about the state of their own full node.<br />
  The only nefarious act the service provider can do, by itself, is retain information: we can say someone never signed a proof when they actually did sign it.<br /><br />
  Every single scripts that is run on your computer, either to sync, create proofs or batch verify them are 
  <a href="https://github.com/bitcoinsocialsync/scripts" target="_blank">open sourced</a><br />
</div>


<div id="question" class="h4">How to verify a proof?</div>
<div id="answer">
  There is two possibility: <br />
  - Run our verification script to batch verify every single proof existing on the server. <i>(coming soon)</i><br />
  - Manually for each proof by following this process: <br />
  <ol>
    <li>Go to a community member's profile and check the twitter link to his PGP Fingerprint.</li>
    <li>Use PGP to verify that the PGP Public Key displayed on our website match his PGP Fingerprint displayed on Twitter.</li>
    <li>Use PGP to verify the proofs displayed on his profile are signed by that key.</li>
  </ol>
</div>
<!-- 
<div id="question" class="h4">How are proofs created?</div>
<div id="answer">
  Proofs can be
  We have to trust each participant to have independently verified the hash_serialized .<br />
  They can to that either by running  script that will pilot their node into generating that proof 
  before signing it with their PGP key and uploading it to our server.
</div> -->

<div id="question" class="h4">Who can create proofs?</div>
<div id="answer">
  Any Bitcoin node owner that has:<br />
  <ul>
    <li>A full node with enough block history</li>
    <li>A Twitter account</li>
    <li>A PGP key</li>
    <li>That's it!</li>
  </ul>
</div>

<div id="question" class="h4">Why should you bother create proofs as a node owner?</div>
<div id="answer">
  Making the Initial Blockchain Download & construction of the UTXO set easier is one of bigger roadblocks in having every single Bitcoin user running its own full node.<br />
  This service try to facilitate that step, while requiring as little trust as possible, by integrating the social aspect of Bitcoin into it's synching mechanism. 
</div>

<div id="question" class="h4">How to create a proof for a specific block?</div>
<div id="answer">
  First of all, you need to register your PGP Fingerprint through our Twitter Bot: <code>@BitcoinSync register MY_PGP_FINGERPRINT</code><br />
  After, you need to <a routerLink="/pubkey">upload your PGP PubKey</a> on our server.<br />
  To automatically create a proof and upload it to our server, you can <a href="https://raw.githubusercontent.com/bitcoinsocialsync/scripts/master/proof-generator.sh" download target="_blank">run our automated proof-generator.sh script</a><br />
  Or follow those manual steps: 
  <ol>
    <li><a routerLink="/browse">Choose one of the proposed blocks on the dashboard you're going to create a proof for</a></li>
    <li>run the following command: <code>bitcoin-cli invalidateblock BLOCKHASH_TO_INVALIDATE</code> and wait for it to finish</li>
    <li>run the following command: <code>bitcoin-cli gettxoutsetinfo</code></li>
    <li>Take the hash_serialized2 and blockHeight results from the previous command, compare them with the ones on the dashboard and if they are matching, use them to sign a PGP proof <code>echo "BitcoinSocialSync.com: BLOCK_HEIGHT HASH_SERIALIZED" | gpg --clearsign -a --output BLOCK_HEIGHT.bssproof</code></li>
    <li><a routerLink="/newProof">Upload that proof to our server</a></li>
    <li>All that remains is for you to reset your node into its previous state with <code>bitcoin-cli reconsiderblock BLOCKHASH_TO_INVALIDATE</code></li>
    <li>That's it, your done!</li>
  </ol>
</div>

