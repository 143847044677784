import { ApiService } from '../api.service';
import { Component, OnInit } from '@angular/core';
import { User } from '../user';

@Component({
  selector: 'app-pubkey',
  templateUrl: './pubkey.component.html',
  styleUrls: ['./pubkey.component.css']
})
export class PubkeyComponent implements OnInit {

  constructor(
    private apiService: ApiService
  ) { }

  user = new User();
  submitted = false;
  error = false;

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;
  }

  submit() {
    this.apiService.registerPubKey(this.user).subscribe(user => {
      if (user) {
        console.log(user);
        location.href = '/user/' + user.user.twitterName;
      } else {
        this.error = true;
      }
    });
  }
}
