import { Chainstate } from './../chainstate';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { debounceTime, map, tap, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from '../user';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public isCollapsed = true;
  public searchValue = '';
  // public proofs: OrderedProofs;
  public chainstates: Chainstate[];
  objectKeys = Object.keys;
  searching: boolean;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.setDefaultChainstates();
  }

  getChainstate(id: number) {
    if (this.chainstates[id].proofs == null || this.chainstates[id].proofs === undefined) {
      this.apiService.getChainstate(this.chainstates[id].id).subscribe(proofs => { this.chainstates[id].orderedProofs = proofs; });
    }
  }

  setDefaultChainstates() {
    this.apiService.getChainstates().subscribe(chainstates => {
      this.chainstates = chainstates;
      this.chainstates[0].isCollapsed = true;
      this.getChainstate(0);
    });
  }

  getProofs(id: number, key: string) {
    return this.chainstates[id].orderedProofs ? this.chainstates[id].orderedProofs[key] : [];
  }

  selectedSearchResult(result: NgbTypeaheadSelectItemEvent) {
    console.log('here', result);
    this.apiService.getChainstatesByUser(result.item.id).subscribe(chainstates => {
      this.chainstates = chainstates;
      this.chainstates[0].isCollapsed = true;
      this.getChainstate(0);
    });
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length <= 2 ? [] : this.apiService.search(term)),
      tap(() => this.searching = false)
    )
  formatter = (x: {twitterName: string}) => x.twitterName;
}
