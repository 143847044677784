import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { User, UserAug } from './user';
import { Proof, OrderedProofs } from './proof';
import { Chainstate } from './chainstate';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiUrl = (window.location.host === 'localhost:4200') ? 'http://localhost:3030/api/' : 'https://bitcoinsocialsync.com/api/';

  constructor(
    private http: HttpClient
  ) { }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.apiUrl)
    .pipe(
      catchError(this.handleError<User[]>('getUsers', []))
    );
  }

  getUser(twitterName: string): Observable<UserAug> {
    const url = `${this.apiUrl}users/${twitterName}`;
    return this.http.get<UserAug>(url).pipe(
      tap(_ => console.log(`fetched User twitterName=${twitterName}`)),
      catchError(this.handleError<UserAug>(`getUser twitterName=${twitterName}`))
    );
  }

  registerPubKey(user: User): Observable<UserAug> {
    return this.http.post<UserAug>(this.apiUrl + 'users/confirm', user).pipe(
      tap(_ => console.log(`register pubkey User =${user}`)),
      catchError(this.handleError<UserAug>(`register pubkey id=${user}`))
    );
  }

  newProof(twitterName: string, proof: string): Observable<Proof> {
    return this.http.post<Proof>(this.apiUrl + 'users/proof', { twitterName, proof }).pipe(
      tap(_ => console.log(`Added proof for =${twitterName}`)),
      catchError(this.handleError<Proof>(`Added proof for =${twitterName}`))
    );
  }

  getProofs(chainstateId: number): Observable<Proof[]> {
    return this.http.get<Proof[]>(this.apiUrl + `chainstate/${chainstateId}/proofs`).pipe(
      tap(_ => console.log(`list of proofs`)),
      catchError(this.handleError<Proof[]>(`list of proofs`))
    );
  }

  getChainstates(): Observable<Chainstate[]> {
    return this.http.get<Chainstate[]>(this.apiUrl + 'chainstates').pipe(
      tap(_ => console.log(`list of Chainstates`)),
      catchError(this.handleError<Chainstate[]>(`list of Chainstates`))
    );
  }

  getChainstatesByUser(userId: number): Observable<Chainstate[]> {
    return this.http.get<Chainstate[]>(this.apiUrl + 'chainstates/users/' + userId).pipe(
      tap(_ => console.log(`list of Chainstates by user`)),
      catchError(this.handleError<Chainstate[]>(`list of Chainstates by user`))
    );
  }

  getChainstate(id: number): Observable<OrderedProofs> {
    return this.http.get<OrderedProofs>(this.apiUrl + 'chainstates/' + id).pipe(
      tap(_ => console.log(`Proofs for Chainstate: ${id}`)),
      catchError(this.handleError<OrderedProofs>(`Proofs for Chainstate: ${id}`))
    );
  }

  search(twitterName: string): Observable<User[]> {
    // text$.pipe(
    //   debounceTime(200),
    //   map(term => term === '' ? [] : statesWithFlags.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    // )
    const url = `${this.apiUrl}search/${twitterName}`;
    return this.http.get<User[]>(url).pipe(
      tap(_ => console.log(`search Users starting by=${twitterName}`)),
      catchError(this.handleError<User[]>(`search Users starting by=${twitterName}`))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      console.error(`${operation} failed: ${error.message}`);

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
