import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Proof } from '../proof';

@Component({
  selector: 'app-new-proof',
  templateUrl: './new-proof.component.html',
  styleUrls: ['./new-proof.component.css']
})
export class NewProofComponent implements OnInit {

  constructor(
    private apiService: ApiService
  ) { }

  proof = {
    twitterName: '',
    proof: ''
  };
  submitted = false;
  error = false;

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;
  }

  submit() {
    const twitterName = this.proof.twitterName;
    this.apiService.newProof(this.proof.twitterName, this.proof.proof).subscribe(result => {
      if (result) {
        location.href = '/user/' + twitterName;
      } else {
        this.error = true;
      }
    });
  }
}
