<div id="downloads">
  <div id="script-download" class="row text-center">
    <div class="col-xs-12 col-md-5">
      <h4>Do you want to sync your full node simply and automatically?</h4>
      <div class="d-md-none">
        <a href="#" target="_blank" class="btn btn-lg btn-bitcoin-color">
          <span class="fas fa-download"></span> Sync Script
        </a>
      </div>
    </div>
    <div class="col-xs-12 col-md-2"><h3 class="or-download">OR</h3></div>
    <div class="col-xs-12 col-md-5">
      <h4>Do you want to contibute to this community effort by creating your own proofs?</h4>
      <div class="d-md-none">
        <a href="#" target="_blank" class="btn btn-lg btn-dark">
          <span class="fas fa-download"></span> Proof Script
        </a>
      </div>
    </div>
  </div>
  <div class="row text-center d-none d-md-flex">
    <div class="col-xs-12 col-md-5">
      <a href="https://raw.githubusercontent.com/bitcoinsocialsync/scripts/master/installer.sh" download target="_blank" class="btn btn-lg btn-bitcoin-color">
        <span class="fas fa-download"></span> Sync Script
      </a>
    </div>
    <div class="col-xs-12 col-md-2"></div>
    <div class="col-xs-12 col-md-5">
      <a href="https://raw.githubusercontent.com/bitcoinsocialsync/scripts/master/proof-generator.sh" download target="_blank" class="btn btn-lg btn-dark">
        <span class="fas fa-download"></span> Proof Script
      </a>
    </div>
  </div>
</div>
<hr />
<div id="search-proofs" class="row">
  <ng-template #rt let-r="result" let-t="term">
    <img [src]="r.twitterPicture" class="mr-1" style="width: 16px">
    <ngb-highlight [result]="r.twitterName" [term]="t"></ngb-highlight>
  </ng-template>
  <input name="searchValue" id="searchValue" type="text" class="col-4 form-control" placeholder="Search for a specific signer you trust"
        [(ngModel)]="searchValue" [ngbTypeahead]="search" [resultTemplate]="rt" [inputFormatter]="formatter" (selectItem)="selectedSearchResult($event)"  />
  <div class="col-1 icons-search">
    <span class="fas fa-spinner text-bitcoin-color" *ngIf="searching"></span>  
    <span class="fas fa-times text-danger clickable" *ngIf="searchValue" (click)="setDefaultChainstates(); searchValue = ''"></span>
  </div>
</div>
<div id="blockProofs">
  <div class="card" *ngFor="let chainstate of chainstates; let i = index">
    <div class="card-header clickable text-center h4" id="BlockHeight" (click)="chainstate.isCollapsed = !chainstate.isCollapsed; getChainstate(i);">{{ chainstate.blockCreationDate }} BlockHeight: #{{ chainstate.blockHeight }} <span class="badge badge-light" *ngIf="chainstate.proofsCount">{{ chainstate.proofsCount }} proofs </span><span class="fas fa-chevron-down" alt="show public key"></span></div>
    <!-- <h4 class="card-title bg-dark" id="BlockHeight" (click)="isCollapsed = !isCollapsed">BlockHeight: #000000 <span class="badge badge-light">#xxxx proofs</span></h4> -->
    <div id="collapseBody" [ngbCollapse]="!chainstate.isCollapsed">
      <div class="card-body">
        <div class="row">
            <div class="col-md-8 col-xs-12">
              <h5 class="d-flex justify-content-center">Block details</h5>
            </div>
            <div class="col-md-4 col-xs-12">
              <h5 class="text-center">Signers</h5>
            </div>
        </div>
        <div class="row block-inner">
          <div class="col-md-8 col-xs-12 block-details">
            <div><h6>Block hash <small>{{ chainstate.blockHash }}</small></h6></div>
            <div><h6>Block to invalidate <small>{{ chainstate.blockHashInvalidate }}</small></h6></div>
            <div><h6>UTXO set serialized hash <small>{{ chainstate.hashSerialized }}</small></h6></div>
            <!-- <button class="mb-0 btn btn-bitcoin-color">Download UTXO Set</button> -->
            <div class="manual-downloads text-center">
              <h5>Manual downloads</h5>
              <div class="manual-utxo">
                <a href="https://downloads.bitcoinsocialsync.com/utxo-set/utxo-set-{{ chainstate.blockHeight }}.tar.gz" 
                   class="btn btn-lg btn-bitcoin-color" target="_blank">
                  <span class="fas fa-download"></span> UTXO Set & Blocks
                </a>
              </div>
              <!-- <div id="manual-blockchain" class="text-center">
                  <a href="https://downloads.bitcoinsocialsync.com/blockchain/chain-full.tar.gz" target="_blank" class="btn btn-lg btn-dark">
                    <span class="fas fa-download"></span> Full blockchain
                  </a>
                  <a href="https://downloads.bitcoinsocialsync.com/blockchain/chain-pruned.tar.gz" target="_blank" class="btn btn-lg btn-dark">
                    <span class="fas fa-download"></span> Pruned blockchain
                  </a>
              </div> -->
            </div>
          </div>
          <div class="col-md-4 col-xs-12 signers" *ngIf="chainstate?.orderedProofs">
            <div *ngFor="let key of objectKeys(chainstate.orderedProofs)">
              <div class="card">
                <div class="card-header">{{ key | titlecase }}</div>
                <div class="card-body">
                  <span *ngFor="let proof of getProofs(i,key)">
                      <a [routerLink]="['/user/', proof.owner.twitterName]">
                      <img [src]="proof.owner.twitterPicture" [alt]="proof.owner.twitterName" id="twitterPicture" class="rounded-circle" /></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>