import { FaqComponent } from './faq/faq.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PubkeyComponent } from './pubkey/pubkey.component';
import { HomeComponent } from './home/home.component';
import { NewProofComponent } from './new-proof/new-proof.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserComponent } from './user/user.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'browse', component: DashboardComponent },
  { path: 'pubkey', component: PubkeyComponent },
  { path: 'newProof', component: NewProofComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'user/:twitterName', component: UserComponent },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
