import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() {
    // this.search.valueChanges
    //           .debounceTime(400)        // wait for 400ms pause in events
    //           .distinctUntilChanged()   // ignore if next search term is same as previous
    //           .subscribe(term => this.wikipediaService.search(term).then(items => this.items = items));
  }

  ngOnInit() {
  }

}
