<form (ngSubmit)="onSubmit()" #pubkeyForm="ngForm">
  <div class="form-group">
    <label for="TwitterName">Twitter Name</label>
    <input [(ngModel)]="user.twitterName" type="text" pattern="^[A-Za-z0-9_]{1,32}$" class="form-control" name="TwitterName" id="TwitterName" aria-describedby="TwitterNameHelp" placeholder="Enter your Twitter Name" required>
    <small id="TwitterNameHelp" class="form-text text-muted">We need this to link your twitter identity and your public key</small>
  </div>
  <!-- <div class="form-group">
    <label for="TwitterLink">Link to Twitter post sharing pubkey fingerprint and signature proving ownership of the key</label>
    <input [(ngModel)]="user.twitterLink" type="url" pattern="https?://twitter.com/.+" class="form-control" name="TwitterLink" id="TwitterLink" aria-describedby="TwitterLinkHelp" placeholder="Enter the link" required>
    <small id="TwitterLinkHelp" class="form-text text-muted">This post should never be deleted from your Twitter feed.</small>
  </div> -->
  <div class="form-group">
    <label for="PubKey">Your PGP PubKey</label>
    <textarea [(ngModel)]="user.pgpPubKey" pattern="^-----BEGIN PGP PUBLIC KEY BLOCK-----\n[\s\S]*-----END PGP PUBLIC KEY BLOCK-----\n?$" class="form-control" name="PubKey" id="PubKey" aria-describedby="PubKeyHelp" placeholder="Enter your PGP Public Key" required></textarea>
  </div>
  <button type="submit" class="btn btn-lg btn-bitcoin-color" [disabled]="!pubkeyForm.form.valid" (click)="submit(); pubkeyForm.reset()">Submit</button>
</form>
<div class="alert alert-danger" role="alert" *ngIf="error">
    Failed to add PubKey
</div>