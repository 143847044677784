<form (ngSubmit)="onSubmit()" #proofForm="ngForm">
  <div class="form-group">
    <label for="TwitterName">Twitter Name</label>
    <input [(ngModel)]="proof.twitterName" type="text" pattern="^[A-Za-z0-9_]{1,32}$" class="form-control" name="TwitterName" id="TwitterName" aria-describedby="TwitterNameHelp" placeholder="Enter your Twitter Name" required>
    <small id="TwitterNameHelp" class="form-text text-muted">We need this to link your twitter identity and your public key</small>
  </div>
  <div class="form-group">
    <label for="proof">Your PGP Signed Proof</label>
    <textarea [(ngModel)]="proof.proof" pattern="^-----BEGIN PGP SIGNED MESSAGE-----\n[\s\S]*-----END PGP SIGNATURE-----\n?$" class="form-control" name="proof" id="proof" aria-describedby="proofHelp" placeholder="Enter your PGP Signed Proof" required></textarea>
  </div>
  <button type="submit" class="btn btn-lg btn-bitcoin-color" [disabled]="!proofForm.form.valid" (click)="submit(); proofForm.reset()">Submit</button>
</form>
<div class="alert alert-danger" role="alert" *ngIf="error">
    Failed to add proof!
</div>