<div class="row" *ngIf="user">
  <div class="col-12 col-md-4 text-center left-side">
    <!-- <div class="d-flex justify-content-center"> -->
    <div><img [src]="user?.twitterPicture" [alt]="user?.twitterName" class="rounded-circle"></div>
    <div><h4>{{ user.twitterName }}</h4></div>
    <div><h5 class="text-bitcoin-color">{{ user?.group?.name || 'Community Member' }}</h5></div>
  </div>
  <div class="col-12 col-md-8 right-side">
    <div>PGP Fingerprint: {{ user?.pgpFingerprint | uppercase }} <a [href]="proofLink" target="_blank"><span class="fab fa-twitter"></span>Proof</a></div>
    
    <div class="card">
      <div class="card-header clickable" id="BlockHeight" (click)="isCollapsedPubKey = !isCollapsedPubKey">PGP Public Key <span class="fas fa-chevron-down" alt="show public key"></span></div>
      <div class="card-body" [ngbCollapse]="!isCollapsedPubKey"><code>{{ user?.pgpPubkey }}</code></div>
    </div>
    <div class="proofs">
      <h5>Signed Proofs:</h5>
      <div class="card" *ngFor="let proof of user.proofs">
        <div class="card-header clickable" id="BlockHeight" (click)="proof.chainstate.isCollapsed = !proof.chainstate.isCollapsed">{{ proof.chainstate.blockCreationDate }} BlockHeight: #{{ proof.chainstate.blockHeight }} <span class="fas fa-chevron-down" alt="show public key"></span></div>
        <div class="card-body" [ngbCollapse]="!proof.chainstate.isCollapsed">
          <code>{{ proof.proof }}</code>
        </div>
      </div>
    </div>
  </div>
</div>