import { Proof } from './proof';
import { Group } from './group';

export class User {
  id: number;
  pgpFingerprint: string;
  pgpPubKey: string;
  proofLink: string;
  twitterName: string;
  proofs: Proof[];
  groupId: number;
  group: Group;
}

export interface UserAug {
  user: User;
  prooflink: string;
}
