
<nav class="navbar navbar-expand-lg">
  <a class="navbar-brand" routerLink="/home">
    <!-- <img src="assets/images/bitcoin.svg" width="30" height="30" class="d-inline-block align-top" alt=""> -->
    <img src="assets/images/logo4.png" width="200" height="50" class="d-inline-block align-top" alt="">
    <!-- BitcoinSocialSync -->
  </a>
  <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed" aria-controls="navbarText" aria-label="Toggle navigation">
    <span class="fas fa-bars"></span>
  </button>
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarText" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/browse" routerLinkActive="active">Browse</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/pubkey" routerLinkActive="active">Upload Public Key</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/newProof" routerLinkActive="active">Submit Hash Serialized Proof</a>
        </li>
        <!-- <li class="nav-item">
            <a class="nav-link" routerLink="/faq" routerLinkActive="active">FAQ</a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>
<div class="container">
  <router-outlet></router-outlet>
</div>
<footer class="bg-dark text-white text-center h5">
  <div class="col-12 links">
    <span id="twitter"><a href="https://twitter.com/bitcoinsync" target="_blank"><span class="fab fa-twitter"></span></a></span>
    <span id="github"><a href="https://github.com/BitcoinSocialSync" target="_blank"><span class="fab fa-github"></span></a></span>
  </div>
  <div id="copyright" class="col-12">&copy; <a href="https://twitter.com/SylTilt" target="_blank">SylTi</a></div>
  <!-- <div id="github">GitHub: <a href="https://github.com/SylTi" target="_blank">SylTi</a></div> -->
</footer>